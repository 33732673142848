@import 'mixins';

$sideBarWidth: 200px;

$searchBoxHeightDesktop: 48px;
$searchBoxHeightMobile: 75px;

// sizes
$xxs: 275px;
$xs: 320px;
$sm: 576px;
$md-sm: 650px;
$md: 768px;
$md-lg: 880px;
$lg: 1024px;
$lgr: 1180px;
$xl: 1340px;
$xlr: 1480px;
$xxl: 1920px;

$sizes: (
  xxs: $xxs,
  xs: $xs,
  sm: $sm,
  md-sm: $md-sm,
  md: $md,
  md-lg: $md-lg,
  lg: $lg,
  lgr: $lgr,
  xl: $xl,
  xlr: $xlr,
  xxl: $xxl,
);

// breakpoints min
$bp-min-xs: 'min-width: #{$xs}';
$bp-min-sm: 'min-width: #{$sm}';
$bp-min-md: 'min-width: #{$md}';
$bp-min-md-lg: 'min-width: #{$md-lg}';
$bp-min-lg: 'min-width: #{$lg}';
$bp-min-lgr: 'min-width: #{$lgr}';
$bp-min-xl: 'min-width: #{$xl}';
$bp-min-xlr: 'min-width: #{$xlr}';
$bp-min-xxl: 'min-width: #{$xxl}';

// breakpoints max
$bp-max-xs: 'max-width: #{$xs - 1}';
$bp-max-sm: 'max-width: #{$sm - 1}';
$bp-max-md: 'max-width: #{$md - 1}';
$bp-max-md-lg: 'max-width: #{$md-lg - 1}';
$bp-max-lg: 'max-width: #{$lg - 1}';
$bp-max-lgr: 'max-width: #{$lgr - 1}';
$bp-max-xl: 'max-width: #{$xl - 1}';
$bp-max-xlr: 'max-width: #{$xlr - 1}';
$bp-max-xxl: 'max-width: #{$xxl - 1}';

// If you add a color here and want it available on class names
//   also add it to the colors array below.
// default and brand colors
$black: #000;
$white: #fff;
$off-white: #f7f7f7;
$dark-grey: #333;
$grey: #626366;
$light-grey: #e5e5e5;
$deployment-blue: #0c214d;
$medium-blue: #3f6bab;
$deployment-body: #6a6a6a;
$tri-brand: #2a467b;
$highlight-blue: #e4f0ff;
$border-grey: #bdbdbd;
$royal-yellow: #f9c700;
$filter-blue: #0c214d;

$colors: (
  black: $black,
  white: $white,
  dark-grey: $dark-grey,
  grey: $grey,
  light-grey: $light-grey,
);

$border-color: $grey;

// layouts variables
$app-max-width: 1920px;
$content-max-width: 1440px;
$content-min-width: 720px;
$content-max-width-alt: 1080px;
$medium-max-width: 992px;

// containers
$max-width-container: 1440px;
$max-width-text-container: 660px;

// typography variables
$primary-heading-font-family: 'proxima-nova', sans-serif;
$primary-body-font-family: 'proxima-nova', sans-serif;

$font-size-base: 16px;
$font-size-small: $font-size-base - 3;
$font-color-base: $deployment-body;
$font-weight-base: normal;
$font-weight-heading: $font-weight-base;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$line-height-copy: 1;
$line-height-heading: 1;
$letter-spacing: 1px;

// margin and padding
$col-gutter: 20px;
$mb-base: 20px;

// borders and shadows
$border-light: 1px solid $light-grey;
$border-main: 1px solid $border-color;

// buttons
$button-font-weight: 600;
// colors
$primary-btn-background: $deployment-blue;
$primary-btn-color: $white;
$alt-button-background: $white;
$alt-button-color: $black;
// transitions
$transition-speed: 0.25s;

// helpers
$axes: top, right, bottom, left;
$spacing-properties: (
  p: padding,
  m: margin,
);

$axes: (
  b: bottom,
  l: left,
  r: right,
  t: top,
);
