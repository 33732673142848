@import '../configuration/variables';

label {
  font-size: $font-size-base - 0.1;
  margin: 0 0 15px 0;

  @media screen and ($bp-min-lg) {
    font-size: $font-size-base;
  }

  @media screen and ($bp-min-xxl) {
    font-size: $font-size-base + 0.3;
  }
}

select,
textarea,
input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'] {
  background-color: #fff;
  border: $border-light;
  font-size: $font-size-base - 0.2;
  padding: 0 10px;
  height: 40px;
  width: 100%;

  &.error {
    border: 1px solid red;
  }

  @media screen and ($bp-min-md) {
    font-size: $font-size-base;
  }

  @media screen and ($bp-min-xl) {
    height: 50px;
  }

  @media screen and ($bp-min-xxl) {
    font-size: $font-size-base + 0.3;
    height: 60px;
  }
}

select,
input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'] {
  @include appearance-none();
  border-radius: 0;
  box-shadow: none;
}

input[type='checkbox'] {
  @include appearance-none();
  background-color: rgba(0, 0, 0, 0);
  border: $border-light;
  border-radius: 0;
  margin: 0 20px 0 0;
  height: 26px;
  width: 30px;

  &[value='true'] {
    background-image: url(../../assets/icons/checkmark.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
  }

  & + label {
    font-size: $font-size-base - 0.3;
    text-align: left;
  }
}

label,
select {
  cursor: pointer;
}

select {
  background-image: url(../../assets/icons/arrow-down.svg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 28px;
  padding-right: 24px;
}

textarea {
  @include appearance-none();
  background-color: rgba(0, 0, 0, 0);
  border: $border-light;
  min-height: 180px;
  padding-top: 20px;
}
