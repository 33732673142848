@import '../../styles/configuration/variables';

.deployments-container {
  @include Panel;

  > header {
    padding: 28px 28px 10px 28px;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: $deployment-blue;
      margin-bottom: 22px;
    }
  }
  > main {
    padding: 16px 30px;
  }
  @media only screen and (max-width: $md-lg) {
    > header {
      padding: 8px 12px;
    }
    > main {
      padding: 12px;
    }
  }

  .nothing-found-error {
    color: #0c214d;
    font-weight: bold;
    font-style: italic;
  }
}
