@import '../../styles/configuration/variables';

.component-alert {
  width: 100%;
  border: 3px solid #6b35b5;
  border-radius: 25px;
  display: grid;
  grid-template-columns: 120px auto;
  padding: 23px 30px;
  background-color: white;

  & .icon-wrapper {
    margin-top: 18px;
    margin-left: 10px;
    border-right: 1px solid #cdcfd1;
  }

  & .content-wrapper {
    margin-left: 26px;

    & .title {
      font-size: 20px;
      color: #6b35b5;
      margin-bottom: 8px;
      font-weight: bold;
    }

    & .body {
      font-size: 15px;
      line-height: 24px;
      color: #6a6a6a;
    }
  }
  @media only screen and (max-width: $md-lg) {
    display: block;
    padding: 18px 15px;
    .icon-wrapper {
      float: left;
      border-right: none;
      margin: 0 12px 12px 0;
      img {
        width: 38px;
      }
    }
    .content-wrapper {
      margin: 0;
      .title {
        margin: 12px;
      }
      .body {
        clear: both;
      }
    }
  }
}
