@import '../../styles/configuration/variables';

.filters-popout {
  flex-direction: column;
  width: 90%;
  max-width: 824px;
  margin: 20px auto;
  height: 100%;
  max-height: calc(100vh - 40px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  &__container {
    border: 1px solid $tri-brand;
  }

  &__title-bar {
    background: $tri-brand;
    padding: 30px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__title {
      color: #fff;
      font-weight: bold;
      font-size: $font-size-base + 4;
      font-family: $primary-heading-font-family;
    }

    &__close {
      text-transform: uppercase;
      color: #fff;
      appearance: none;
      border: none;
    }
  }

  &.flex-center {
    display: flex;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow-y: auto;
    background: #fff;
    max-height: 60vh;

    &__section {
      padding-bottom: 20px;

      &__options {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include MQ(sm) {
          flex-direction: row;
        }

        &__option {
          display: flex;
          flex-direction: row;

          input[type='checkbox'] {
            appearance: auto;
            margin: 0 10px 0 0;
          }

          @include MQ(sm) {
            width: 30%;
          }

          @include MQ(lg) {
            width: 20%;
          }
        }
      }

      &.section-port,
      &.section-destination {
        .filters-popout__content__section__options {
          &__option {
            @include MQ(md) {
              width: 50%;
            }

            @include MQ(lg) {
              width: 32.2%;
            }
          }
        }
      }
    }
  }

  &__actions {
    padding: 30px 40px;
    background: $off-white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__clear,
    &__apply {
      appearance: none;
      border: none;
      color: #005edc;
    }

    .close-x,
    .apply-arrow {
      color: $deployment-body;
    }
  }
}
