@import '../../styles/configuration/variables';

.component-map-search {
  pointer-events: none !important;
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  background-color: #f7f7f7;
  padding: 20px;
  margin-top: 16px;
  border-bottom: 1px solid #cdcfd1;
  border-top: 1px solid #cdcfd1;

  &:focus-within {
    .component-map-search-container .component-map-search-results {
      display: flex;
    }
  }

  .component-map-search-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    z-index: 2;
    @include MQ(md-lg) {
      position: relative;
    }

    .component-map-search-input-wrapper {
      pointer-events: all;
      height: $searchBoxHeightMobile;
      padding: 12px;
      background-color: $white;

      @include MQ(md-lg) {
        padding: 0;
        height: $searchBoxHeightDesktop;
        background-color: transparent;
      }

      .component-map-search-input {
        display: flex;
        @include Rounded(2px);
        position: relative;
        top: 0;
        align-items: center;
        padding: 12px;
        border: 1px solid $light-grey;
        background-color: $white;

        &__icon {
          width: 24px;
          height: 24px;
          margin: 0 5px 0 0;
        }

        &__input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;

          &:focus {
            border: none;
            outline: none;
          }

          &::placeholder {
            font-style: italic;
            font-size: $font-size-base - 2;
          }
        }
      }
    }

    .component-map-search-results {
      pointer-events: all;
      position: absolute;
      top: $searchBoxHeightMobile - 20px; // offset for margin
      background-color: #fff;
      width: calc(100% - 24px);
      display: none;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 7px 0 0 12px;
      height: calc(100vh - 154px);
      max-height: 600px;
      min-height: 65px;
      @include DropShadow(0.06);
      @include MQ(md-lg) {
        top: $searchBoxHeightDesktop;
        width: 100%;
        margin: 0;
      }

      &__result {
        background-color: $white;
        display: flex;
        flex-direction: row;
        padding: 5px 10px 5px 50px;
        align-items: center;
        cursor: pointer;
        font-size: $font-size-base - 2;
        appearance: none;
        border: none;
        color: $deployment-body;
        text-decoration: none;

        &:hover {
          background: $highlight-blue;
        }

        &__icon {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
  }
}
