@import '../../styles/configuration/variables';

.component-pin-filters-wrapper {
  pointer-events: none;
  display: flex;
  justify-content: center;

  .component-pin-filters {
    pointer-events: all;
    @include DropShadow(0.1);
    @include Rounded(4px);
    position: relative;
    z-index: 200;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background-color: $deployment-blue;
    padding: 10px 16px;
    margin: 32px 10px 32px 0;

    @include MaxMQ(md-lg) {
      margin: 10px auto;
    }

    @include MaxMQ(xs) {
      .component-pin-filters__title {
        display: none;
      }
    }

    &__title {
      text-transform: uppercase;
      color: $off-white;
      font-family: $primary-body-font-family;
      margin: 0 15px 0 1px;
      letter-spacing: 0.05em;
    }

    &__pins {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      padding: 0 0 0 0px;
      margin: 0;

      &__pin {
        display: flex;
        margin: 1px;
        padding: 0;

        &:before {
          content: '';
        }

        &__button {
          border: 1px solid $off-white;
          padding: 10px 20px;
          border-radius: 3px;
          color: $off-white;
          font-weight: 600;
          letter-spacing: 0.05em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &:hover {
            border: 1px solid $royal-yellow;
          }

          &.active {
            border: 1px solid $royal-yellow;
            background-color: $royal-yellow;
            color: $deployment-blue;
            &:hover {
              border: 1px solid $off-white;
            }
          }
        }

        &__label {
          font-family: $primary-body-font-family;
          text-transform: uppercase;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

/*
  We have this here as the existence of this component is what displaces the position of the zoom
*/
.mapboxgl-ctrl-top-right {
  top: 0px;

  @include MQ(md-lg) {
    top: 70px !important;
  }
}
