@import '../configuration/config';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $font-color-base;
  font-size: $font-size-base;
  font-family: $primary-body-font-family;
}

html,
body {
  scroll-behavior: smooth;

  @include OnIE {
    height: 100%;
  }
}

body {
  overflow-y: scroll;
  overflow-x: auto;
}

body,
#rcl__deployment-hub,
.rcl__deployment-hub {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

body.ReactModal__Body--open {
  overflow: hidden;
}
