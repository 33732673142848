@import '../../styles/configuration/variables';

.pin-filters-extended {
  padding: 20px 40px;

  button {
    background-color: $light-grey;
    color: rgba(74, 74, 74, 0.4);

    &.active,
    &:hover {
      background-color: $royal-yellow;
      color: #15264c;
    }
  }

  p {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #4a4a4a;
  }

  .pin-filters-brands {
    .pin-filters-brand {
      display: block;
    }
  }

  .pin-filters-date-ranges {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;

    .pin-filters-date-range {
      flex: 0 0 30.333333%;
      font-weight: 700;
      font-size: 14px;
      line-height: 17.05px;
      border: none;
      padding: 9px 16px;
      text-transform: uppercase;
      border-radius: 4px;
      letter-spacing: 4px;
    }
  }

  .pin-filters-years {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;

    .pin-filters-date-year {
      flex: 0 0 31.333333%;
      font-weight: 700;
      font-size: 12px;
      line-height: 14.62px;
      border: none;
      padding: 4px 18.5px;
      text-transform: uppercase;
      border-radius: 4px;
      letter-spacing: 4px;
    }
  }

  .pin-filters-months {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 16px;

    .pin-filters-month {
      flex: 0 0 23%;
      font-weight: 700;
      font-size: 14px;
      line-height: 14.62px;
      border: none;
      padding: 17.5px 12.25px;
      text-transform: uppercase;
      border-radius: 4px;
      letter-spacing: 4px;
    }
  }

  .pin-filters-brands {
    .pin-filters-brand {
      margin-bottom: 10px;
      width: 100%;
      padding: 11.5px 42.5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17.05px;
      text-transform: uppercase;
      letter-spacing: 2px;
      border: none;
      border-radius: 4px;
    }
  }
}
