@import '../../configuration/config';
#rcl__deployment-hub {
  // TEXT
  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .bold {
    font-weight: $font-weight-bold;
  }
  .normal {
    font-weight: $font-weight-base;
  }
  .font-heading {
    font-family: $primary-heading-font-family;
  }
  .font-body {
    font-family: $primary-body-font-family;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .no-text-decoration {
    text-decoration: none;
  }
  .no-text-transform {
    text-transform: none;
  }
  .no-wrap {
    white-space: nowrap;
  }
  @for $i from 10 through 32 {
    .f-#{$i} {
      font-size: #{$i}px;
    }
  }
}
