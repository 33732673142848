//   ========================================================================
//   Mixins
//   ========================================================================

//Background image
@mixin bg-image($bgAttachment, $bgColor, $bgSize, $bgRepeat, $bgPosition) {
  background-attachment: $bgAttachment;
  background-color: $bgColor;
  background-size: $bgSize;
  background-repeat: $bgRepeat;
  background-position: $bgPosition;
}

// Transitions
@mixin transition($type: all, $time: 0.25s, $effect: ease-in-out) {
  -webkit-transition: $type $time $effect;
  -moz-transition: $type $time $effect;
  -o-transition: $type $time $effect;
  -ms-transition: $type $time $effect;
  transition: $type $time $effect;
}

//Built limited width container
@mixin limit-content-width(
  $display_type,
  $margin_anchor,
  $max_width,
  $text_anchor,
  $width_percentage
) {
  display: $display_type;
  margin: $margin_anchor;
  max-width: $max_width;
  text-align: $text_anchor;
  width: $width_percentage;
}

//Offset horizontal and vertical axis
@mixin align-axis($x_offset, $y_offset) {
  -moz-transform: translate($x_offset, $y_offset);
  -ms-transform: translate($x_offset, $y_offset);
  -o-transform: translate($x_offset, $y_offset);
  -webkit-transform: translate($x_offset, $y_offset);
  transform: translate($x_offset, $y_offset);
}

//Offset vertical axis
@mixin align-y-axis($y_offset) {
  -moz-transform: translateY($y_offset);
  -ms-transform: translateY($y_offset);
  -o-transform: translateY($y_offset);
  -webkit-transform: translateY($y_offset);
  transform: translateY($y_offset);
}

//Offset horizontal axis
@mixin align-x-axis($x_offset) {
  -moz-transform: translateX($x_offset);
  -ms-transform: translateX($x_offset);
  -o-transform: translateX($x_offset);
  -webkit-transform: translateX($x_offset);
  transform: translateX($x_offset);
}

//Border Box
@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//Appearance override
@mixin appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

//Remove copy & CheckBoxInput
@mixin remove-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//Media Queries
@mixin MQ($canvas) {
  // breakpoints

  @if $canvas == xs {
    //xs
    @media only screen and ($bp-min-xs) {
      @content;
    }
  } @else if $canvas == sm {
    //sm
    @media only screen and ($bp-min-sm) {
      @content;
    }
  } @else if $canvas == md {
    //md
    @media only screen and ($bp-min-md) {
      @content;
    }
  } @else if $canvas == md-lg {
    //md
    @media only screen and ($bp-min-md-lg) {
      @content;
    }
  } @else if $canvas == lg {
    //lg
    @media only screen and ($bp-min-lg) {
      @content;
    }
  } @else if $canvas == lgr {
    //lgr
    @media only screen and ($bp-min-lgr) {
      @content;
    }
  } @else if $canvas == xl {
    //xl
    @media only screen and ($bp-min-xl) {
      @content;
    }
  } @else if $canvas == xlr {
    //xlr
    @media only screen and ($bp-min-xlr) {
      @content;
    }
  } @else if $canvas == xxl {
    //xxl
    @media only screen and ($bp-min-xxl) {
      @content;
    }
  }
}

@mixin MaxMQ($canvas) {
  // breakpoints
  @if $canvas == xs {
    //xs
    @media only screen and ($bp-max-xs) {
      @content;
    }
  } @else if $canvas == sm {
    //sm
    @media only screen and ($bp-max-sm) {
      @content;
    }
  } @else if $canvas == md {
    //md
    @media only screen and ($bp-max-md) {
      @content;
    }
  } @else if $canvas == lg {
    //lg
    @media only screen and ($bp-max-lg) {
      @content;
    }
  } @else if $canvas == md-lg {
    //md
    @media only screen and ($bp-max-md-lg) {
      @content;
    }
  } @else if $canvas == lgr {
    //lgr
    @media only screen and ($bp-max-lgr) {
      @content;
    }
  } @else if $canvas == xl {
    //xl
    @media only screen and ($bp-max-xl) {
      @content;
    }
  } @else if $canvas == xlr {
    //xlr
    @media only screen and ($bp-max-xlr) {
      @content;
    }
  } @else if $canvas == xxl {
    //xxl
    @media only screen and ($bp-max-xxl) {
      @content;
    }
  }
}

//Conditionally Target IE10 and 11
@mixin OnIE($canvas: null) {
  @if $canvas == null {
    //Default without media query
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == xs {
    //xs
    @media only screen and ($bp-min-xs) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == sm {
    //sm
    @media only screen and ($bp-min-sm) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == md {
    //md
    @media only screen and ($bp-min-md) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == lg {
    //lg
    @media only screen and ($bp-min-lg) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == lgr {
    //lg
    @media only screen and ($bp-min-lgr) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == xlr {
    //xlr
    @media only screen and ($bp-min-xlr) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == xl {
    //xl
    @media only screen and ($bp-min-xl) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  }
}

@mixin Panel {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: content-box;
  background-color: $off-white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'main';

  > header {
    grid-area: header;
    &.mobile {
      @include MobileDrawerHeader;
    }
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    color: $deployment-blue;
    margin: 18px 0;
    @include MaxMQ(md-lg) {
      font-size: 26px;
      font-weight: normal;
    }
  }

  > main {
    grid-area: main;
    overflow-y: auto;
    overflow-x: hidden;

    .title {
      @include MaxMQ(md-lg) {
        margin: 18px 0;
      }
    }
  }
}

@mixin DropShadow($weight: 0.01) {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, $weight);
}

@mixin Rounded($value: 3px) {
  border-radius: $value;
}

@mixin ShowAdditionalInformation {
  .show-additional-information {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 24px;
      color: $deployment-blue;
      font-weight: bold;
    }

    .show-more-button {
      text-transform: uppercase;
      color: inherit;
    }
  }

  .additional-information {
    padding-top: 30px;
    transition: max-height, padding 0.5s;
    max-height: 1000px;
    overflow: hidden;

    &.hidden {
      padding-top: 0;
      max-height: 0;
    }
  }
}

@mixin MobileDrawerHeader {
  @include DropShadow(0.1);
  position: relative;
  padding: 0 18px;
  .title-row {
    margin: 6px 70px 10px 0;
    display: inline-flex;
    align-items: center;

    img {
      height: 26px;
      width: 26px;
    }

    .title {
      font-size: 26px;
      font-weight: normal;
      color: $deployment-blue;
      margin: 0 15px;
    }
  }
}

@mixin ClickThroughDetailsLayout {
  @include Panel;

  > header {
    @include DropShadow;
    background-color: white;
  }

  > main {
    padding-top: 30px;
    padding-bottom: 30px;

    & .details-section {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .alert-wrapper,
    .details-section {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  @media only screen and (max-width: $md-lg) {
    > header {
      background-color: white;
    }
    > main {
      margin: 0;
      padding: 10px 0 0 0;

      .alert-wrapper,
      .details-section {
        margin-left: 18px;
        margin-right: 18px;
      }

      @include ShowAdditionalInformation;
    }
  }
}
