@import '../../styles/configuration/variables';

.map-zoom-control {
  &.hidden {
    opacity: 0;
    top: 20px;
  }

  @include MQ(md-lg) {
    margin: 30px 10px;
    top: 210px;
    right: 20px;
  }

  .btn-normal {
    display: block;
    padding: 10px 12px;
    background-color: white;
    border: 2px solid $grey;

    &:hover {
      background-color: darken($alt-button-background, 10%);
    }

    @include MaxMQ(md-lg) {
      padding: 6px 8px;
    }
  }

  .zoom-in {
    border-bottom-width: 1px;
  }

  .zoom-out {
    border-top-width: 1px;
  }

  button > .icon {
    width: 20px;
    height: 20px;
  }

  @include MaxMQ(md-lg) {
    position: absolute;
    top: 86px; /* under map search control */
    right: 10px;
  }
}
