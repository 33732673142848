@import '../../styles/configuration/variables';

.component-ship-menu-item {
  margin-top: 25px;

  & .title {
    font-size: 18px;
    margin-bottom: 6px;
  }

  & .links {
    display: flex;
    column-gap: 27px;

    & span {
      font-weight: 600;
      cursor: pointer;
    }
  }
}
