@import '../../styles/configuration/variables';

.article-container {
  @include ClickThroughDetailsLayout;

  > header.desktop {
    background-color: $off-white;
  }

  > header {
    .clickthrough-header {
      align-items: flex-start;
      padding: 30px 0 0 0;
      @include DropShadow(0);
      .title {
        color: $tri-brand;
        font-weight: bold;
        width: 50%;
        max-width: 420px;
      }
    }
  }
  > main {
    background-color: $off-white;
    padding: 22px 30px;

    .title {
      color: $tri-brand;
      font-weight: bold;
    }

    > .title {
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    article {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: $md-lg) {
    > main {
      padding: 18px;
    }
    .clickthrough-header {
      margin: 0;
    }
  }
}
