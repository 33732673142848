@import '../../styles/configuration/config';

.ReactModalPortal {
  max-height: 100%;
}

.modal-overlay {
  &.overlay-higher {
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.modal.modal-content {
  outline: none;
  background: none;

  @include MaxMQ(md) {
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    height: 100%;
  }

}
