@import '../../styles/configuration/variables';

.mapboxgl-marker:hover {
  z-index: 10020 !important;
}

.mapboxgl-marker {
  z-index: 10010;

  &.portofcall {
    z-index: 10000;
  }
}

.hide-animation,
.flipping-pin-labels {
  .mapboxgl-marker .marker {
    .marker-label {
      opacity: 0 !important;
    }
  }
}

$marker-icon-width: 28px;
$marker-icon-height: 35px;

$marker-large-icon-width: 46px;
$marker-large-icon-height: 56px;

.marker {
  position: absolute;
  top: -($marker-icon-height);
  left: -($marker-icon-width / 2);
  font-family: $primary-body-font-family;
  font-size: 14px;

  &.world-view.region,
  &.world-view.homeport,
  &.region-view.homeport {
    .marker-label {
      background-color: $deployment-blue;
      color: $off-white;
    }
  }

  &:hover {
    z-index: 10000;

    .marker-card-wrapper {
      pointer-events: all !important;
    }

    &.fades-in {
      .marker-card-wrapper {
        z-index: 100;

        .marker-card {
          max-height: none;
        }
      }
    }
  }

  .marker-icon-wrapper {
    cursor: pointer;
    width: $marker-icon-width;
    height: $marker-icon-height;

    .marker-icon {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 200;
      width: $marker-icon-width;
      height: $marker-icon-height;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
      filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.3));
    }
  }

  .count {
    display: inline-block;
    border-radius: 50%;
    color: $deployment-blue;
    background-color: $royal-yellow;
    height: 20px;
    width: 20px;
    z-index: 100;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  .marker-label {
    @include DropShadow(0.7);
    cursor: pointer;
    position: absolute;
    white-space: nowrap;
    z-index: 50;
    left: 15px;
    top: 1px;
    margin-top: 5px;
    background-color: $white;
    padding: 1px 12px 1px 18px;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 1;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.3));
    transition: opacity 0.3s ease;

    &.transparent {
      display: none;
    }
  }

  .marker-card-wrapper {
    pointer-events: none;
    position: relative;
    top: -3px;
    left: -4px;

    .marker-card {
      @include DropShadow(0.7);
      position: absolute;
      min-width: 200px;
      top: -($marker-icon-height);
      left: 0;
      padding: (10px + 3px) 10px 20px (35px + 4px);
      background-color: $white;
      border-radius: 10px 3px 3px 3px;

      .header {
        white-space: nowrap;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 6px;
        margin-bottom: 12px;
        margin-top: -3px;
        display: flex;
        justify-content: space-between;

        img {
          margin-top: -3px;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .count {
        margin: 0 5px;
      }

      .title {
        font-weight: bold;
        color: $deployment-blue;
      }

      .basic-link {
        display: block;
        cursor: pointer;
      }
    }
  }

  .group {
    margin-bottom: 15px;
  }

  &.brand {
    top: -($marker-large-icon-height);
    left: -($marker-large-icon-width / 2);

    &.conflicts.brand-code-R {
      left: -($marker-large-icon-width / 2) +32px;
    }

    &.conflicts.brand-code-C {
      left: -($marker-large-icon-width / 2) - 32px;
    }

    .marker-icon-wrapper {
      width: $marker-large-icon-width;
      height: $marker-large-icon-height;

      .marker-icon {
        width: $marker-large-icon-width;
        height: $marker-large-icon-height;

        .count {
          position: absolute;
          top: -5px;
          right: -7px;
          padding: 0 0 0 1px;
        }
      }
    }

    .marker-card-wrapper {
      top: -7px;
      left: -3px;

      .marker-card {
        padding: 20px 10px 10px 30px;
        top: -($marker-large-icon-height);

        .note {
          font-style: italic;
          color: $deployment-body;
          padding: 10px 35px 10px 75px;
          white-space: nowrap;
        }

        .title {
          margin: 8px 0 0 0;
        }

        .ships {
          max-height: 270px;
          overflow-x: hidden;
          overflow-y: auto;
          padding: 0 35px;

          .ship {
            margin-bottom: 10px;

            button {
              font-size: 17px;
              font-weight: 700;
              white-space: nowrap;
            }

            img {
              width: 30px;
              margin-right: 10px;
            }

            .sails-to {
              margin-left: 40px;
              padding-bottom: 12px;
              border-bottom: 1px solid $light-grey;
            }
          }
        }
      }
    }
  }

  &.flipped {
    direction: rtl;
    left: auto;
    right: -14px;

    .marker-label {
      left: auto;
      right: 15px;
      padding: 0 20px 0 8px;
    }

    .marker-card-wrapper {
      left: auto;
      right: 0;
      top: -4px;

      .marker-card {
        padding: (10px + 3px) (35px + 4px) 20px 10px;
        border-radius: 3px 10px 3px 3px;
        left: auto;
        right: -4px;

        .header {
          direction: rtl;

          img {
            margin-left: 0;
            margin-right: 12px;
          }
        }
      }
    }

    .direction {
      direction: ltr;
    }

    .group,
    .basic-link {
      direction: ltr;
      margin-left: 36px;
    }

    &.brand {
      left: auto;
      right: -($marker-large-icon-width / 2);

      .marker-card-wrapper {
        top: -7px;

        .marker-card {
          right: -12px;
          padding: 20px 30px 10px 10px;

          .ships {
            padding: 0;

            > * {
              direction: ltr;
            }

            .ship {
              .sails-to {
                margin-left: 50px;
              }

              .basic-link {
                margin-left: 10px;
              }
            }
          }

          .note {
            direction: ltr;
            padding-left: 65px;
            padding-right: 50px;
          }
        }
      }
    }
  }

  &.expands-x {
    .marker-label {
      animation-name: revert-expand-x;
      animation-duration: 0.4s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      transform: scaleX(0);
      transform-origin: 0;
    }

    &:hover .marker-label {
      animation: expand-x 0.4s ease;
      animation-fill-mode: forwards;
      transform: scaleX(1);
    }

    &.fades-in .marker-label {
      box-shadow: none;
      animation: none;
    }
  }

  @keyframes expand-x {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  @keyframes revert-expand-x {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  &.fades-in {
    .marker-card-wrapper {
      animation: fade-out-dimension-0 0.4s ease;
      animation-fill-mode: forwards;

      .marker-card {
        animation: fade-in-dimension-0-offset 0.4s ease;
        animation-fill-mode: forwards;
      }
    }

    &:hover {
      .marker-card-wrapper {
        animation: fade-in-dimension-0 0.4s ease;
        animation-fill-mode: forwards;
      }

      .marker-label {
        display: none;
      }
    }
  }

  @keyframes fade-in-dimension-0-offset {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in-dimension-0 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      width: auto;
      height: auto;
      overflow: visible;
    }
  }
  @keyframes fade-out-dimension-0 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.region-view.portofcall {
    .marker-label {
      display: none;
    }
  }

  &.region-view.homeport,
  &.region-view.portofcall {
    .marker-card {
      .ships {
        margin: 10px 0 0 0;

        .ship {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          white-space: nowrap;

          img {
            margin-right: 10px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  &.region {
    .marker-icon {
      background-image: url('../../assets/icons/regions-pin.png');
    }

    .marker-label {
      background-color: $deployment-blue;
      color: $off-white;
    }
  }

  &.homeport .marker-icon {
    background-image: url('../../assets/icons/ports-pin.png');
  }

  &.portofcall .marker-icon {
    background-image: url('../../assets/icons/ports-of-call-pin.png');
  }

  &.brand.brand-code-C .marker-icon {
    background-image: url('../../assets/icons/celebrity-pin.svg');
  }

  &.brand.brand-code-R .marker-icon {
    background-image: url('../../assets/icons/royal-carribean-pin.svg');
  }
}
