@import '../configuration/config';

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.modal {
  background-color: #fff;
  left: 50%;
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
