@import '../../styles/configuration/variables';

.drawer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 250;
  height: 100%;
  max-width: calc(100% - #{$sideBarWidth} - 50px); // 100% - sidebar - close X
  overflow: hidden;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  transform: translateX(-100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.right {
    left: auto;
    right: 0;
    transform: translateX(100%);
  }

  > main {
    height: 100%;
  }

  &.open {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX($sideBarWidth);
    overflow: visible;

    &.right {
      transform: translateX(0);
    }
  }

  & .panel {
    height: 100%;
  }

  > .close-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 0 5px 5px 0;
    background-color: $primary-btn-background;
    box-shadow: 1px 0 3px 0 #00000040;
  }
}

@include MaxMQ(md-lg) {
  .drawer {
    max-width: 100%;
    z-index: 1001;

    &.open {
      transform: translateX(0px);
    }

    > .close-button {
      right: 15px;
      top: 18px;
      width: auto;
      height: auto;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
      display: flex;
      align-items: baseline;

      label {
        text-transform: uppercase;
        font: inherit;
      }

      svg.mobile {
        margin-left: 10px;
      }
    }
  }
}
