@import '../../styles/configuration/variables';

.component-deployment-changes-table {
  > .header {
    @include DropShadow(0.25);
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;

    & .title {
      color: $deployment-blue;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
      padding: 0 32px;
    }
  }

  .deployments {
    .actions {
      margin: 0 -5px;
      & button {
        padding: 4px 18px;
        line-height: 24px;
        border-radius: 12px;
        border: none;
        font-size: 14px;
        margin: 0 5px;

        &.book {
          background-color: $deployment-blue;
          color: white;
        }

        &.quote {
          background-color: white;
          color: $deployment-blue;
          border: 1px solid $deployment-blue;
        }
      }
    }
  }

  table.desktop {
    min-width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(6, auto);
    margin-left: -12px;
    width: calc(100% + 12px);
    & thead {
      display: contents;
      font-weight: 700;
      color: $deployment-blue;
      line-height: 50px;
    }

    & tbody {
      display: contents;
      font-size: 14px;
      color: black;
      font-weight: 400;
      tr:nth-child(odd) {
        background-color: $white;
      }
    }

    tr {
      display: contents;
      text-align: left;
      padding: 0 10px;

      & td,
      th {
        background-color: inherit;
        padding-right: 12px;
        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          padding-right: 12px;
        }
      }

      & td {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  @media only screen and (max-width: $md-lg) {
    > .header {
      padding-top: 20px;
      padding-bottom: 0;
      .title {
        padding: 0 18px;
      }
    }

    .deployments {
      .mobile-deployment-grid-wrapper:nth-child(odd) {
        background-color: $white;
      }

      .mobile-deployment-grid-wrapper {
        margin-left: -8px;
        width: calc(100% + 8px);

        .mobile-deployment-grid {
          display: grid;
          grid-template-columns: 33% auto;
          grid-gap: 5px;
          padding: 8px 0;

          > div:nth-child(2n + 1) {
            padding-left: 8px;
          }
          .title {
            color: $deployment-blue;
            font-weight: bold;
            font-size: inherit;
          }

          > .actions {
            display: block;
            grid-column: span 2;
          }
        }
      }
    }
  }
}
