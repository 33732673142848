@import '../configuration/config';

a:link,
a:visited,
.basic-link {
  color: #005edc;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  font-family: inherit;
}

a:hover,
a:active,
a:visited,
.basic-link:hover {
  text-decoration: none;
}

button.basic-link {
  font-size: inherit;
  padding: 0;
}

input[type='submit'],
button {
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-shadow: none;
  &:disabled {
    opacity: 0.5;
  }
}

button {
  background-color: transparent;

  &:focus {
    border-radius: 0;
  }
}

.btn {
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: $font-size-base - 0.3;
  font-weight: $button-font-weight;
  padding: $col-gutter - 5 $col-gutter;
  transition: background-color $transition-speed linear;
  text-transform: uppercase;
  @media screen and ($bp-min-md) {
    font-size: $font-size-base - 0.1;
  }

  &:not(.btn-text-only) {
    letter-spacing: $letter-spacing;
  }

  &.btn-text-only {
    background-color: transparent;
    padding: 0;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.btn-primary {
    background-color: $primary-btn-background;
    color: $primary-btn-color;

    &:hover {
      background-color: lighten($primary-btn-background, 10%);
    }
  }

  &.btn-alt {
    background-color: $alt-button-background;
    color: $alt-button-color;

    &:hover {
      background-color: darken($alt-button-background, 20%);
    }
  }

  &.btn-transparent {
    background-color: transparent;
    border: $border-light;
    color: rgba(0, 0, 0, 0);

    &:hover {
      background-color: $black;
    }
  }
}
