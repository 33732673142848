@import '../../styles/configuration/variables';

header {
  .clickthrough-header {
    min-height: 86px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include DropShadow(0.1);
    position: relative;

    > :first-child:not(.icon) {
      margin-left: 30px;
    }
  }
}
.clickthrough-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon img {
    display: block;
    width: 86px;
    height: 86px;
  }

  .pin {
    margin-left: 18px;
    margin-right: 6px;

    img {
      display: block;
      width: 40px;
    }
  }

  .title {
    flex-grow: 1;
    color: $medium-blue;
    font-size: 20px;
    font-weight: bold;
  }

  .share {
    margin-right: 60px;

    button.basic-link {
      font-size: 14px;
      text-decoration: none;
      padding: 0;

      img {
        margin-left: 6px;
      }
    }
  }

  @media only screen and (max-width: $md-lg) {
    flex-wrap: wrap;
    margin: 10px 18px;
    @include DropShadow(0);
    > .pin {
      margin-left: 0;
      display: inline-block;

      img {
        width: 46px;
      }
    }
    > .title {
      display: inline-block;
      margin-right: 12px;
      min-width: calc(100% - #{$sideBarWidth});
    }
    > .share {
      margin: 18px 0;
    }
  }
}
