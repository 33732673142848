@import '../../styles/configuration/variables';

.select-with-label {
  label {
    color: $grey;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 12px;
    font-family: $primary-body-font-family;
  }

  .label-text {
    margin-bottom: 2px;
  }

  select {
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $medium-blue;
    font-family: $primary-body-font-family;
    display: block;
    width: auto;
    height: auto;
    padding: 0 14px 0 0;
    background-image: url(../../assets/icons/right-arrow.svg);
    background-size: 6px;
  }
}
