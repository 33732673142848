@import '../../styles/configuration/variables';

// full width screen container
.landing-modal {
  display: flex;
  justify-content: center;

  // center container
  .center-content {
    position: relative;
    margin: 6px;
    background-color: white;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
    max-width: 910px;

    .background-image {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      border: 6px solid white;
    }
  }

  // fill the parent
  .assets-content {
    position: relative;
    overflow: auto;
    padding-bottom: 40px;
  }

  .top-row {
    display: flex;
    justify-content: flex-end;
    padding: 8px 8px 0 8px;
  }

  .close-button {
    border: none;
    padding-top: 15px;
    padding-right: 12px;
    cursor: pointer;
  }

  svg.logo {
    margin: 0 auto 12px auto;
    display: block;
    height: 90px;
  }

  .welcome-row {
    display: block;
  }

  .welcome-text {
    font-family: 'Kapra-Regular';
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.02em;
  }

  .launch {
    cursor: pointer;
  }

  .intro-row {
    padding: 0 138px;
    margin-bottom: 32px;

    p {
      color: white;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .search-input-row {
    padding: 0 158px;
    margin-bottom: 12px;
    position: relative;

    input {
      outline: none;
      height: 48px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 2px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9a9a9a;
      opacity: 1; /* Firefox */
      font-style: italic;
    }

    .with-search-icon {
      padding-left: 50px;
      background-image: url(../../assets/icons/search-icon.svg);
      background-repeat: no-repeat;
      background-position: 12px center;
    }
  }

  .footer-row {
    display: flex;
    justify-content: center;
  }

  .component-map-search-results {
    pointer-events: all;
    position: absolute;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 7px 0px 0 12px;

    /* constrain to 5 results */
    height: calc(31px * 5);

    display: flex;

    @include MQ(md-lg) {
      top: 48px;
      width: calc(100% - 317px);
      margin: 0;
    }

    &.open-upwards {
      flex-direction: column-reverse;
      top: unset;
      bottom: 48px;
    }

    &__result {
      background-color: $white;
      display: flex;
      flex-direction: row;
      padding: 5px 10px 5px 50px;
      align-items: center;
      cursor: pointer;
      font-size: $font-size-base - 2;
      appearance: none;
      border: none;
      color: $deployment-body;
      text-decoration: none;
      width: 100%;

      &:hover {
        background: $highlight-blue;
      }

      &__icon {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

  @media only screen and (max-width: 880px) {
    .intro-row {
      p {
        margin-bottom: 0;
      }
    }

    .footer-row {
      display: none;
    }

    .component-map-search-results {
      top: 41px;
      left: 146px;
      width: calc(100% - 316px);
    }
  }

  @media only screen and (max-width: 768px) {
    height: 100%;

    .background-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .assets-content {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .top-row {
      padding: 24px 8px;
    }

    .intro-row {
      padding: 24px;
    }

    .search-input-row {
      margin-top: 36px;
      padding: 0 120px;
    }

    .component-map-search-results {
      top: 41px;
      left: 108px;
      width: calc(100% - 240px);
    }

    .footer-row {
      display: flex;
    }
  }

  @media only screen and (max-width: 576px) {
    .welcome-row {
      display: none;
    }
    .search-input-row {
      margin-top: 24px;
      padding: 0 60px;
    }

    .component-map-search-results {
      top: 41px;
      left: 48px;
      width: calc(100% - 120px);
    }
  }

  @media only screen and (max-width: 440px) {
    .close-button {
      padding-top: 0;
      padding-right: 0;
    }

    .brand-logo {
      width: 150px;
      height: auto;
    }

    .search-input-row {
      margin-top: 12px;
      padding: 0 30px;
    }

    .component-map-search-results {
      top: 41px;
      left: 18px;
      width: calc(100% - 60px);
    }
  }
}
