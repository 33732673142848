@import '../configuration/config';
#rcl__deployment-hub {
  @page {
    margin: 0.5cm 0.5cm;
  }

  @media print {
    * {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  .no-print {
    @media print {
      display: none;
    }
  }
}
