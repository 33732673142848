@import '../../styles/configuration/variables';

.clickthrough-details {
  display: grid;
  grid-template-columns: 1fr auto; /* TODO: Revisit when we have more images */
  column-gap: 10px;

  .landscape-image {
    // 16:9 aspect ratio
    width: 400px;
    height: 225px;
    background-position: center;
    background-size: cover;
  }

  & img {
    display: block;
  }

  & .overview-row {
    display: grid;
    grid-template-columns: auto auto auto;
    font-size: 14px;
    width: 100%;
    margin: 20px 0;
    column-gap: 10px;

    & .title {
      color: $deployment-blue;
      font-weight: bold;
    }
  }

  & .bio-title {
    color: $deployment-blue;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  & .bio-content {
    line-height: 28px;
  }

  & .info-title {
    font-weight: 700;
    color: $deployment-blue;
    font-size: 14px;
    margin-bottom: 2px;
  }

  & .info-content {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 17px;
  }

  @media only screen and (max-width: $md-lg) {
    display: block;
    & .overview-row {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 16px;
      grid-template-areas: 'item';
      > .item {
        display: contents;
        grid-area: item;
        margin-bottom: 12px;
      }
    }
    img {
      max-width: none;
    }
  }
}
