@import '../../styles/configuration/variables';

.rcl__deployment-hub {
  height: 100%;
}

.mapboxgl-ctrl-top-right {
  display: none; // hide zoom controls
}

.mapboxgl-ctrl-bottom-left {
  display: none; // hide logo
}

.mapboxgl-ctrl-bottom-right {
  display: none; // hide copyright
}

.map-container-wrapper {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);

  @include MQ(md-lg) {
    top: 0;
    left: $sideBarWidth;
    width: calc(100% - #{$sideBarWidth});
    height: 100%;
  }

  .map-container {
    position: relative;
    height: 100%;
  }

  .gradient-bg-overlay {
    pointer-events: none; /* enable click through */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      rgba(0, 0, 20, 0.6) 0%,
      rgba(255, 0, 0, 0) 30%
    );
  }
}

.map-controls-wrapper {
  position: relative;
  z-index: 400;
  display: table;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .map-controls {
    position: relative;
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: bottom;

    .filter-trigger-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    > * {
      pointer-events: all;
    }

    @include MQ(md-lg) {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      left: $sideBarWidth;
      width: calc(100% - #{$sideBarWidth});
    }

    .component-filters {
      @include DropShadow(0.1);
      position: relative;
      z-index: 200;
      padding: 8px 0 8px 16px;
      max-width: 380px;

      .filter-button {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        border: none;
        text-transform: uppercase;
        background: $filter-blue;
        color: $royal-yellow;
        font-weight: 600;
        font-size: 12px;
        padding: 20px 24px;
        border-radius: 6px 0 0 6px;
      }
    }

    .vertical-stack-controls {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      transition: all 0.7s ease;
      transition-property: opacity, top;

      pointer-events: none;

      button {
        pointer-events: auto;
      }

      @include MQ(md-lg) {
        margin: 10px;
        top: 80px;
        right: 0;
        max-width: 500px;
      }

      @include MaxMQ(md-lg) {
        position: initial;
        top: 106px; /* under map search control */
        right: 10px;
      }
    }
  }

  // end of .map-controls

  @include MQ(md-lg) {
    z-index: 150;

    &.view-WORLD {
      .vertical-stack-controls {
        top: 60px;
      }
    }

    &.view-PORT {
      .vertical-stack-controls {
        top: 0; /* reset to align screen top */
      }
    }
  }
}

// end of .map-controls-wrapper

.advance-pin-filters-drawer {
  background-color: #fff;
  width: 400px;
  height: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  hr {
    border: 1px solid $light-grey;
    width: 100%;
    margin-bottom: 20px;
  }

  .advance-pin-filters-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 16px 20px 0;

    .icon-container {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 17.05px;
        text-transform: uppercase;
        color: $filter-blue;
        letter-spacing: 1px;
      }
    }

    button {
      text-transform: uppercase;
      border: none;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      justify-content: flex-end;
      display: flex;
    }
  }

  .result-select {
    display: flex;
    //flex-direction: row;
    gap: 10px;
    align-items: center;
    padding-top: 20px;

    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 17.05px;
      text-transform: uppercase;
      color: $filter-blue;
      letter-spacing: 1px;
    }
  }
}
