@import '../../styles/configuration/variables';

.announcements-container {
  @include Panel;
  background-color: $off-white;

  width: 1000px;
  max-width: 100%;

  > header {
    grid-area: header;
    padding: 25px 20px 18px 20px;
  }

  > main {
    grid-area: main;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px 20px 20px;

    button.show-more-button {
      margin: 24px 0;
    }

    table {
      border-spacing: 0;
      // override inline styles coming from the HTML content
      width: 100% !important;
      height: auto !important;

      thead {
        tr {
          td,
          th {
            text-align: left;
          }
        }
      }

      tbody tr:nth-child(2n + 1) {
        background-color: $light-grey;
      }

      td,
      th {
        &:first-child {
          width: 10em;
        }
        &:last-child {
          width: 12em;
          text-align: right;
        }
      }
    }
  }

  @include MaxMQ(md-lg) {
    > header {
      background-color: white;
    }
    > main {
      padding: 18px 18px 0 18px;
    }
  }
}
