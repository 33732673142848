@import '../../styles/configuration/variables';

.list-filters-wrapper {
  overflow: hidden;
  .list-filters {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    justify-content: space-between;

    .select-with-label {
      margin: 0px 20px;
    }

    @media only screen and (max-width: $md-lg) {
      padding: 0 0 10px 0;
      margin: 0 -8px;
      .select-with-label {
        margin: -4px 8px 5px 8px;
      }
    }
  }
}
