@import '../../styles/configuration/variables';

.deployment-card {
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  padding: 0 !important;
  box-shadow: none !important;

  .header-image {
    width: 100%;
    height: 220px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .deployment-card-container {
    padding: 0 10px 10px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .heading {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    height: 29px;
    background-color: transparent;
  }

  .title {
    font-size: 17px;
    font-weight: bold;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0 12px 0;
    margin: 0 0 12px 0;
  }
  .label {
    font-size: 14px;
    font-weight: bold;
  }
  .sails-to {
    font-size: 14px;
    margin: 0 0 12px 0;
  }
  .note {
    font-size: 14px;
    display: block;
    margin: 12px 0 0 0;
  }
  .link {
    font-size: 14px;
    color: $deployment-blue;
    cursor: pointer;

    & span {
      text-decoration: underline;
    }

    &:after {
      margin: 0 0 0 6px;
      content: '\003e';
      color: #6a6a6a;
    }
  }
}
