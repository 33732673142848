@import '../../styles/configuration/variables';

.book-or-quote-package-card-component {
  display: none;
  position: absolute;
  left: -342px;
  top: 30px;
  color: #000;
  border: 1px solid $light-grey;
  background: #fff;
  padding: 10px;
  width: 350px;
  justify-content: space-between;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

  @include MaxMQ(sm) {
    left: 0;
  }

  &.active {
    display: flex;
    flex-direction: row;
    z-index: 1;
  }

  &__content {
    display: block;
    width: 100%;
  }

  &__icon {
    width: 29px;
    height: 29px;
    margin-left: 20px;
  }

  &__title {
    color: $deployment-blue;
    font-size: $font-size-base + 1;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $light-grey;
  }

  &__sail-date-title {
    margin-bottom: 5px;
    color: $deployment-body;
    font-size: $font-size-base - 3;
  }

  &__dates {
    display: flex;
    flex-direction: column;
    max-height: 170px;
    overflow-y: auto;

    &__date {
      appearance: none;
      white-space: nowrap;
      padding: 4px 14px;
      line-height: 24px;
      border: 1px solid $deployment-blue;
      border-radius: 12px;
      font-size: 14px;
      margin: 3px 5px;
      background-color: white;
      color: $deployment-blue;
      cursor: pointer;

      &:hover {
        background: $deployment-blue;
        color: #fff;
      }

      &.active {
        background: $deployment-blue;
        color: #fff;
      }
      &:focus {
        border-radius: 12px;
      }
    }
  }

  &__continue {
    appearance: none;
    margin-top: 20px;
    cursor: pointer;
    padding: 4px 18px;
    line-height: 24px;
    border-radius: 12px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    background-color: white;
    color: $deployment-blue;
    border-color: $deployment-blue;

    &:hover {
      background-color: $light-grey;
    }
  }
}
