@import '../../styles/configuration/variables';

.back-to-upper-view {
  width: 100%;
  padding: 8px;
  text-shadow: 1px 1px rgba(0, 0, 0, 1);
  
  transition: all 0.7s ease;
  transition-property: opacity, top;
  
  &.hidden {
    padding: 16px; // make room for pin filters control in mobile screen
    opacity: 0;
    max-height: 0;

    > * {
      display: none;
    }
  }

  button {
    text-shadow: inherit;
  }

  .region-label {
    color: $white;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 0px;
    text-align: right;
    span {
      padding-left: 4px;
    }
  }

  .region-name {
    color: $white;
    font-size: 30px;
    font-weight: 700;
    text-align: right;
    text-transform: uppercase;
    margin-bottom: 0;
    @include MQ(md-lg) {
      font-size: 48px;
    }
  }

  .back-button-on-right {
    float: left;
    clear: right;
    @include MQ(md-lg) {
      float: right;
      margin: 10px;
    }
  }

  .back-button-label {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    line-height: 23px;
  }

  // add to icon to align text after it
  .back-button-icon {
    vertical-align: middle;
  }

  .btn.btn-text-only {
    text-decoration: none;
  }
}
