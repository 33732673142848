@import '../../styles/configuration/variables';

.ships-container {
  @include Panel;

  > header {
    @include DropShadow;
    padding: 24px;
    background-color: white;
    & .title-row {
      display: grid;
      grid-template-columns: 24px auto 1fr;
      margin-bottom: 24px;
      & .title {
        font-size: 18px;
        margin-left: 12px;
        padding-bottom: 14px;
        border-bottom: 1px solid #cdcfd1;
        color: $deployment-blue;
      }
      img {
        margin-top: 3px;
      }
    }
  }
  main {
    background-color: $off-white;
    padding: 0 60px 30px 60px;
  }
  @media only screen and (max-width: $md-lg) {
    header {
      @include MobileDrawerHeader;
      & .title-row {
        .title {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
    main {
      padding: 0 18px 30px 18px;
    }
  }
}
