@import '../../configuration/config';
#rcl__deployment-hub {
  // hide show based on breakpoints
  @each $key, $size in $sizes {
    .#{$key}-max {
      @include MQ($key) {
        display: none;
      }
    }

    .#{$key}-up {
      @include MaxMQ($key) {
        display: none;
      }
    }
  }
  .container-centered {
    margin-left: auto;
    margin-right: auto;
  }
  @each $key, $size in $sizes {
    .container-#{$key} {
      width: 100%;
      max-width: $size;
    }
    .container-centered-#{$key} {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: $size;
    }
  }

  // POSITIONING
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
}
