@import '../../styles/configuration/variables';

.component-deployment-table {
  > .header {
    @include DropShadow(0.25);
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;

    & .title {
      color: $deployment-blue;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
      padding: 0 32px;
    }

    > .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      & .buttons {
        & button {
          border: 1px solid $deployment-blue;
          border-radius: 4px;
          margin-right: 16px;
          height: 32px;
          width: 110px; /* Maybe do this with a margin instead */
          background-color: white;
          color: $deployment-blue;
          font-size: 14px;

          &.active {
            color: white;
            background-color: $deployment-blue;
          }
        }
      }

      .filters-view {
        border: none;
        appearance: none;
        color: $deployment-blue;

        .down-arrow {
          padding: 0;
          margin: 0;
          height: 15px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .deployments {
    .actions {
      margin: 0 -5px;
      .action {
        position: relative;
        > button {
          padding: 4px 18px;
          line-height: 24px;
          border-radius: 12px;
          border-color: transparent;
          font-size: 14px;
          margin: 0 5px;
          cursor: pointer;

          &.book {
            background-color: $deployment-blue;
            color: white;
            &:hover {
              background-color: lighten($deployment-blue, 10%);
            }
          }

          &.quote {
            background-color: white;
            color: $deployment-blue;
            border-color: $deployment-blue;
            &:hover {
              background-color: $light-grey;
            }
          }
        } // end of button style
      }
    }
  }

  table.desktop {
    min-width: 100%;
    width: 100%;
    padding: 42px 26px 26px 26px;
    display: grid;
    grid-template-columns: auto 120px 110px 160px 1fr auto;
    & thead {
      display: contents;
      font-size: 17px;
      font-weight: 700;
      color: $deployment-blue;
      line-height: 50px;
    }

    & tbody {
      display: contents;
      font-size: 14px;
      color: black;
      font-weight: 400;
    }

    & tr {
      display: contents;
      text-align: left;
      padding: 0 10px;

      &:nth-child(odd) td {
        background-color: white;
        padding-right: 30px;
      }

      & td,
      th {
        &:first-child {
          padding-left: 16px;
        }

        &:last-child {
          padding-right: 16px;
          padding-left: 16px;
        }

        &.no-data-message {
          // the widht of first tow column
          font-style: italic;
          padding-left: 8px;
        }
      }

      & td {
        padding: 16px 16px 16px 0;
      }
    }
  }

  .mobile {
    .no-data-message {
      width: 100%;
      padding: 20px 10px 10px;
      font-style: italic;
    }

    @include MQ(md-lg) {
      display: none;
    }
  }

  @include MaxMQ(md-lg) {
    > .header {
      padding-top: 20px;
      padding-bottom: 0;
      .title {
        padding: 0 18px;
      }
      .filters {
        // FIXME: this name duplicates with belowing class ...
        padding: 0;
        display: block;

        .buttons {
          position: relative;
          padding: 10px 18px 20px 18px;
          border-bottom: 2px solid $grey;
        }

        .filters {
          padding: 20px 18px;
          background-color: $off-white;
          text-align: right;
        }
      }
    }

    .deployments {
      .mobile-deployment-grid-wrapper:nth-child(odd) {
        background-color: $off-white;
      }
      .mobile-deployment-grid-wrapper:nth-child(even) {
        background-color: $white;
      }
      .mobile-deployment-grid-wrapper {
        padding-left: 18px;
        padding-right: 18px;

        .show-more-button {
          color: inherit;
          text-transform: uppercase;
          padding-top: 20px;
          padding-bottom: 20px;

          &.showing {
            padding-bottom: 40px;
          }
        }

        .mobile-deployment-grid {
          display: grid;
          grid-template-columns: 33% auto;
          grid-gap: 20px;
          padding: 30px 0px 10px 0px;
          grid-template-rows: 5px;

          &.hidden :nth-child(n + 5) {
            display: none;
            padding: 20px 0 0 0;
          }

          .title {
            color: $deployment-blue;
            font-weight: bold;
          }

          > .actions {
            display: block;
            grid-column: span 2;
          }
        }
      }
    }
  }

  @include MaxMQ(sm) {
    // in mobile screen

    > .header {
      .filters .buttons button {
        width: 80px;
      }
    }
  }
}
