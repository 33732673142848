@import '../../styles/configuration/variables';

.card-layout {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, minmax(252px, 100%));
  justify-content: space-between;

  &.full-width {
    display: block;

    .card {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .card {
    padding: 10px;
    border: 1px solid #e5e5e5;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
  }

  .columns {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 30px;
    flex-grow: 1;
  }

  @media only screen and (max-width: 1360px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 572px) {
    grid-template-columns: 1fr;
  }
}
