@import '../../styles/configuration/variables';

#side-bar {
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  width: $sideBarWidth;
  height: 100%;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  background-color: $deployment-blue;
  color: $off-white;

  > header {
    grid-area: header;
    padding: 35px 0px;
    text-align: center;

    svg.logo {
      max-width: calc(100% - 30px);
      max-height: 75px;
      margin: 0 15px;

      .ship-text {
        fill: $off-white;
      }
    }
  }

  > main {
    grid-area: main;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 8px;
    > nav > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      > li {
        position: static;
        padding: 0;

        &:hover {
          background-color: rgba(229, 229, 229, 0.5);
          transition: all 0.5s;
        }

        &:before {
          content: '';
        }

        > div {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 9px 0 9px 13px;
          font-size: 16px;
          line-height: 19px;
          border-bottom: 1px solid rgba($light-grey, 0.1);

          &.exit {
            font-size: 14px;
          }

          .icon-with-count-container {
            position: relative;

            .icon-count {
              position: absolute;
              top: -8px;
              right: 11px;
              background-color: $royal-yellow;
              border-radius: 99px;
              width: 14px;
              height: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 1;
              color: #435382;
              font-size: 10px;
              font-weight: 700;
              padding: 0;
            }
          }

          svg {
            flex-shrink: 0;
            height: 24px;
            width: 24px;
            margin-right: 14px;
            fill: $off-white;
            stroke: $off-white;
          }
        }
      }
    }
  } // end of main

  > footer {
    grid-area: footer;

    text-align: center;
    padding: 8px 0;

    a {
      display: inline-block;
      font-weight: bold;
      padding: 8px 0 4px 0;
      font-size: 12px;
      color: #fff;
    }

    .copyright {
      padding: 6px 20px 5px 20px;
      font-size: 12px;
    }
  }

  .menu-toggle {
    display: none;
    position: absolute;
    left: 0;
    bottom: -30px;
    height: 30px;
    padding: 0 20px;
    background-color: $deployment-blue;
    border-bottom: none;
    border-right: none;
    border-left: none;
    border-top: 1px solid rgba($light-grey, 0.1);
    text-transform: uppercase;
    border-bottom-right-radius: 20px;
    color: inherit;

    svg {
      fill: $off-white;
      margin-left: 4px;
      margin-bottom: 2px;
    }

    .toggle-state {
      position: relative;
      margin-left: 6px;
    }
  }

  // hide first in landing page
  &.hide {
    transform: translateX(-100%);
  }
} // end of #side-bar

@media only screen and (max-width: $md-lg) {
  #side-bar {
    width: 214px;
    height: auto;
    transform: translateY(-100%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    top: 0;

    &.open {
      transform: translateY(0);

      .menu-toggle svg {
        transform: rotate(180deg);
      }
    }

    > header {
      display: none;
    }

    > main > nav > ul > li > div {
      padding: 7px 0 6px 15px;
      font-size: 14px;
    }

    > footer {
      .copyright {
        font-size: 10px;
      }
    }

    .menu-toggle {
      @include DropShadow(0.2);
      display: block;
      position: absolute;
    }
  }
}
