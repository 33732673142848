button.show-more-button {
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  img {
    margin-left: 7px;
    transition: transform 0.3s;
  }
  &.showing img {
    transform: rotate(180deg);
  }
}
