@import '../../configuration/config';

#rcl__deployment-hub {
  // COLORS
  @each $name, $color in $colors {
    .color-#{$name} {
      color: #{$color};
    }
    .bg-#{$name} {
      background-color: #{$color};
    }
    @each $sizeName, $size in $sizes {
      .color-#{$name}-#{$sizeName}-up {
        @include MQ($sizeName) {
          color: #{$color};
        }
      }
      .bg-#{$name}-#{$sizeName}-up {
        @include MQ($sizeName) {
          background-color: #{$color};
        }
      }
    }
  }
}
