@import '../../styles/configuration/variables';

.regions-container {
  @include Panel;

  > header {
    @include DropShadow;
    padding: 24px;
    background-color: white;
    height: 86px;

    & .title-row {
      height: 24px;
      line-height: 24px;
      display: grid;
      grid-template-columns: 24px auto 1fr;
      margin-bottom: 42px;

      & .label {
        margin-left: 12px;
        padding-bottom: 14px;
        border-bottom: 1px solid #cdcfd1;
        color: $deployment-blue;
      }
    }
  }
  main {
    background-color: $off-white;
    padding: 0 60px 30px 60px;
  }
}
