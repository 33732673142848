@import '../../styles/configuration/variables';

.deployments-filters {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 0 0 0;
  margin: 0 2px;
  background-color: $white;
  justify-content: space-between;
  align-items: flex-end;

  .select-with-label {
    margin: 0 0px 12px 10px;
    padding-right: 10px;
    width: 100%;

    select {
      width: 100%;
      @include MQ(md) {
        width: 100px;
      }
      @include MQ(xl) {
        width: 150px;
      }
    }
  }

  .group-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    > .group {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: center;
      > .sub-group {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;

        @include MQ(md) {
          flex-wrap: nowrap;
        }
      }
    }
  }

  .group-2 {
    position: relative;
    height: 100%;
    &:before {
      content: '';
      height: 96%;
      position: absolute;
      border-left: 2px solid #cdcfd1;
      left: 0;
    }
  }

  .year-group {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    .year-range-btn {
      white-space: nowrap;
      flex-grow: 1;
      padding: 6px 12px;
      border: 1px solid #005edc;
      border-radius: 4px;
      color: #005edc;
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px 10px 5px;

      &.selected,
      &:hover {
        background-color: #005edc;
        color: $white;
      }
    }
  }

  @media only screen and (max-width: $sm) {
    .group-wrapper {
      display: flex;
      flex-wrap: wrap;

      .group-2:before {
        border: none;
      }
    }
  }
}
