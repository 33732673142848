@import '../../styles/configuration/variables';

.announcement-card {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  .new-tag-container {
    .new-tag {
      font-size: 13px;
      display: inline-block;
      padding: 4px 10px;
      margin: 0 0 12px 0;
      text-transform: uppercase;
      background-color: $primary-btn-background;
      color: white;
    }
  }
  .date {
    font-size: 13px;
    margin: 0 0 12px 0;
  }

  .split {
    padding: 0 0 12px 0;
  }

  .announcement-title {
    position: relative;
    margin-bottom: 12px;
    font-size: 17px;
    font-weight: bold;

    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      display: block;
      width: 250px;
      border-bottom: 1px solid $light-grey;
      max-width: 100%;
    }
  }
  .link {
    text-align: right;
    font-size: 14px;
    white-space: nowrap;
    a {
      white-space: normal;
    }
    img {
      margin: 0 0 0 10px;
    }
  }
}
